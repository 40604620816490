
import React from "react";
import InputForm from "./InputForm";


const CreateExpense = () => {
  return <InputForm />;
};

export default CreateExpense;
